<template>
  <div class="text-gray-900 dark:text-white h-full w-full">
    <!--<div class="hidden sm:block fixed w-full">
      <img class="mx-auto h-10 opacity-85 m-4" src="https://ovatu.com/static/manhattan/img/ovatu/logo-large-navy.png" alt="Ovatu">
    </div>-->

    <div class="h-screen flex flex-col items-center justify-center z-10 sm:px-6 lg:px-8">
      <!--<img v-if="$colorMode.value === 'light'" class="flex-shrink-0 hidden sm:block mx-auto h-10 opacity-85 m-4 mb-6" src="https://ovatu.com/static/manhattan/img/ovatu/logo-large-navy.png" alt="Ovatu">-->
      <img class="hidden sm:block mx-auto h-10 opacity-85 m-4 mb-6" src="https://ovatu.com/static/manhattan/img/ovatu/logo-large-white.png" alt="Ovatu">

      <div class="flex-shrink max-w-sm w-full flex flex-col border dark:border-gray-800 bg-white/80 dark:bg-gray-800/20 py-6 sm:py-6 px-6 sm:px-12 sm:rounded-lg sm:mb-24 backdrop-blur dark:shadow">
        <img v-if="$colorMode.value === 'light'" class="sm:hidden mx-auto h-10 opacity-85 m-4 mb-20" src="https://ovatu.com/static/manhattan/img/ovatu/logo-large-navy.png" alt="Ovatu">
        <div class="flex-shrink">
          <img v-if="$colorMode.value === 'dark'" class="sm:hidden mx-auto h-10 opacity-85 m-4 mb-20" src="https://ovatu.com/static/manhattan/img/ovatu/logo-large-white.png" alt="Ovatu">
          <nuxt-child :location="location" class="flex-shrink" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  layout: 'auth',
  props: {
    location: {
      type: Object,
      default: null
    }
  }
}
</script>
